import React, { useEffect, useState, useContext } from 'react';
import ProgressBar from './progress-bar';
import PricingBox from './pricing-box';
import API from './../../api/api';
import yfLogo from './../../assets/image/png/yf-logo.png';
import trustpilot from './../../assets/image/jpeg/trustpilot.jpg';
import Seo from "./../../components/Seo";
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text } from '../../components/Core';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { getAssociativeArray, getProperty } from "../../utils/helperFn";
import GlobalContext from "../../context/GlobalContext";
import downArrow from './../../assets/image/png/down-arrow.png'

import { navigate, graphql, useStaticQuery } from "gatsby"

const Success = (props) => {
    const [mainProducts, setMainProducts] = useState([]);

    const gContext = useContext(GlobalContext)

    const GradientSection = styled(Section)`
      background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
      margin-top: 100px;
      padding-bottom: 15px;
    `;

    const data = useStaticQuery(graphql`
      query getSuccessGenericRegisterPageData {
          allStrapiOrderJourney {
              nodes {
                  sales_status
                  title
                  subtitle_1
              }
          }
      }`
    );

    const staticText = getAssociativeArray(data.allStrapiOrderJourney.nodes, 'sales_status');

    const renderRegistrationTitle = (pageText) => {

      const buildStatus = gContext.goGetRegistrationData()?.SearchPremiseResults?.properties?.BuildStatus;

      if (buildStatus == null || buildStatus === '0-Backlog') {
          return <Title className="mt-3 mb-4 text-center">Unfortunately, Netomnia is not yet available in your area.</Title>;
      } else {
          return <Title className="mt-3 mb-4 text-center">{getProperty(pageText, 'title')}</Title>;
      }
    }

    const renderRegistrationSubtitle = (pageText) => {

      const buildStatus = gContext.goGetRegistrationData()?.SearchPremiseResults?.properties?.BuildStatus;

      if (buildStatus == null || buildStatus === '0-Backlog') {
          return <Row>
              <Col sm={12} md={9} className="mx-auto mb-4">
                  <Text variant="card" className="text-center mb-4 mx-auto">
                      We're expanding our network all the time; we will let you know if your property is part of any future plans.
                  </Text>
              </Col>
          </Row>
      } else {
          return <Row>
              <Col sm={12} md={9} className="mx-auto mb-4">
                  <Text variant="card" className="text-center mb-4 mx-auto">{getProperty(pageText, 'subtitle_1')}</Text>
                  {getProperty(pageText, 'subtitle_2') && <Text variant="card" className="text-center mb-4 mx-auto">{getProperty(pageText, 'subtitle_2')}</Text>}
              </Col>
          </Row>
      }
    }

    const renderRegistrationStep = () => {
      const registrationData = gContext.goGetRegistrationData();
      const noStatus = registrationData?.SearchPremiseResults?.properties?.NetomniaSalesStatus;
    
      if (noStatus === 'RI_GENERIC' || !noStatus) {
        return 0;
      }
    
      const step1Statuses = ['RI_PHASE3', 'RI_INPLANNING', 'RI_INPLANNING_EX_INBUILD', 'RI_ONHOLD'];
      if (step1Statuses.includes(noStatus)) {
        return 1;
      }
    
      const step2Statuses = ['RI_ONHOLD_EX_INBUILD', 'RI_LANDLORD', 'PRE_ORDER'];
      if (step2Statuses.includes(noStatus)) {
        return 2;
      }
    
      if (noStatus === 'ORDER') {
        return 3;
      }
    };

    // Define text status.
    const textStatus = gContext.goGetRegistrationData()?.SearchPremiseResults ? getProperty(gContext.goGetRegistrationData()?.SearchPremiseResults.properties, 'NetomniaSalesStatus') : undefined

    // Define texts for page with specific sales status or null/undefined.
    const pageText = staticText[textStatus ? textStatus : 'default'];

    useEffect(() => {
      API
            /* 1. Get the offer passed from the Pricing section */
            .get(`/ProductModule/v1.0/cst/Offer/active/RESIDENTIAL?filters=ContractType:CONTRACT,IsDefault:true`)
            .then(response => {
                const products = response?.data?.data?.Product?.dbRecords;
                if (products) {
                    let mainProducts = products.filter(
                      product => product.properties.Type === 'BASE_PRODUCT' && product.properties.ContractType === 'ANNUAL_18'
                  );
                    setMainProducts(mainProducts.sort((a, b) => parseInt(a.properties.Description) - parseInt(b.properties.Description)));
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });
    }, []);

    return (
      <>
        <Seo page="index" />
        <PageWrapper>
          <GradientSection>
            <Container>
              <Text className="text-center mb-5 white-color">
                {gContext.goGetRegistrationData()?.SearchPremiseResults?.title}
              </Text>
              <ProgressBar
                selectedStep={renderRegistrationStep() === 3 ? 4 : renderRegistrationStep()}
                title={renderRegistrationTitle(pageText)}
                subtitle={renderRegistrationSubtitle(pageText)}
              />
              {
                renderRegistrationStep() === 3 ?
                <img src={downArrow} width={70} className='m-auto d-block pt-3'/> : <div className='mb-5 pb-5'></div>
              }
            </Container>
          </GradientSection>
          {
            renderRegistrationStep() === 3 &&
            <Section>
              <Container>
                <Title variant="card" mb="12px" className="text-center">
                  Available partners
                </Title>
                <Text className="text-center mb-5">
                  Now our network is available in your area, you'll be able to choose from a range of offers from
                  our broadband providers. With over 30 to choose from - you can always find the best deal.
                </Text>
                {mainProducts.map(product => (
                  <PricingBox
                    logo={yfLogo}
                    trustpilot={trustpilot}
                    product={product.properties}
                    planName={product.properties.DisplayName}
                    speed={product.properties.Description}
                    rating={5}
                  />  
                ))}
              </Container>
            </Section>
          }
        </PageWrapper>
      </>
    );
};
export default Success;
